.Slider{
    width: 100%;
    display: flex;
    text-transform: capitalize;
}
.Slider .site{
    flex:1 ;
}
.Slider .contener{
    flex: 5;
    


}
.Slider .f{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Slider .top, .bottom{
    padding: 10px;
    margin: 20px;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
}
.Slider .top h1{
    color: #ccc;
    font-weight: 400;
}
.Slider .bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 2rem;
    margin-top: -1.2rem;

}
/* ///////////////////////////////////////// */
@media (max-width:880px) {
    .Slider .bottom{
        flex-direction: column;
    }
    .Slider .left img{
        margin-bottom: 2rem;
        width: 100px !important; 
        height: 100px !important;
    }
}

/* ///////////////////////////////////////// */

.Slider .bottom .left{
    flex: 1;
    text-align: center;
}
.Slider .bottom .left img{
    width: 200px;
}
.Slider .bottom .right{
    /* flex: 2; */
    width: 100%;
}

.Slider .bottom .left video{
    width: 200px;
    /* border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%; */
    text-align: center;
}
.Slider .form{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 30px;
    justify-content: space-around;
    
}
.Slider .form .input_form{
    /* width: 40%; */
}
.Head{
    width: 40%;
}
.Slider .button{
    text-align: center;
}
.Slider .form .input_form input{
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: solid 1px #ccc;
    outline: none;
}
.Slider .form .input_form textarea{
    width: 100%;
    padding: 5px;
    border: none;
    border-bottom: solid 1px #ccc;
    outline: none;
    border: solid 3px #ccc;
    border-radius: 5px;
    min-height: 150px;

}
.Slider .label_file{
    position: relative;
    top: 20px;
    font-size: 20px;
}
.Slider .form .input_form .upload{
    /* position: absolute; */
    top: 20%;
    right: -2rem;
    font-size: 20px;
    
}
.Slider .form button{
    width: 150px;
    padding: 7px;
    background-color: teal;
    color: white;
    border: none;
    font-size: 25px;
    text-transform: capitalize;
}
.password{
    position: relative;
}
.password .eye_password{
    font-size: 20px;
    position: absolute;
    top: 1.5rem;
    right: 5px;
}
.Slider .left video{
    max-width: 200px !important;
    /* max-height: 250px !important; */
    /* border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%; */

}

/* /////////////////////////////////////////////// */
    @media (max-width:560px) {
        .Slider .form{
            flex-direction: column;
            align-items: center;
        }
        .Slider .form .input_form{
            max-width: 250px;
            width: 100%;
        }

    }
/* /////////////////////////////////////////////// */
