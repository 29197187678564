.login-admin {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-admin .main {
  width: 350px;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 2px 4px 10px 1px hsl(0, 0%, 79%);
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
}
.login-admin .main .form {
  height: 300px;
  position: relative;
}
.login-admin .main .form input {
  display: block;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  padding: 5px;
  width: 100%;
}
.login-admin .main .form .password {
  margin-top: 2rem;
  position: relative;
}
.login-admin .main .form .password svg {
  position: absolute;
  width: 20px;
  right: 0;
  bottom: 7px;
  cursor: pointer;
}
.login-admin .main .form button {
  transform: translate(50%, 50%);
  position: absolute;
  bottom: 90px;
  right: 50%;
  background-color: black;
  border: none;
  color: #fff;
  font-size: 20px;
  padding: 4px 0;
  text-transform: capitalize;
  width: 110px;
  border-radius: 3px;
}
.login-admin .main a {
  font-size: 17px;
  text-decoration: underline;
}
.login-admin .top {
  text-align: center;
  margin-bottom: 2rem;
}
.login-admin .top h1 {
  font-size: 25px;
  font-weight: 600;
}
.login-admin .error {
  position: absolute;
  color: red;
  transform: translateY(-50%);
  font-size: 13px;
}
.login-admin .error_email {
  position: absolute;
  color: red;
  font-size: 12px;
}
.login-admin .link_create_account {
  display: block;
  transform: translateY(-200%);
}
.login-admin .link_create_account a {
  color: black;
}

@media (max-width: 563px) {
  .login_page .form button {
    border-radius: 2px !important;
    width: 80px !important;
  }
}/*# sourceMappingURL=Admin.css.map */