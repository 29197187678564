.order{
    display: flex;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.order .side{
    flex: 1;
}
.order .contener{
    flex: 5;
}
.order .contener .list{
    padding: 10px;
    margin: 20px;
}
.order .body_active{
    position: relative;

}
.order .body_active::after{
    content: "";
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.197);
}
@media (max-width:1200px){
    .order .side{
        flex: 0;
    }
}

/* //////////////////////////// */
            /* DARk_mood */
/* //////////////////////////// */
.dark{
    background-color: #111;
}