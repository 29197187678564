@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
body {
  margin:0;
  padding:0;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  font-family:Cairo, sans-serif;
}
a{
  text-decoration: none !important;
  color:#000;
}
svg{
  cursor: pointer;
}
.dark{
  background-color: #111 !important;
}
.ar{
  direction: rtl;
}
textarea{
  border: solid 3px #ccc;
  border-radius: 5px;
  min-height: 150px;
}
/* Add this to your Services.css file */  
  
.MuiDialog-paper {  
  background-color: #fff;  
  color: #333;  
  padding: 20px;  
  border-radius: 10px;  
  box-shadow: 0px 0px 10px rgba(0,0,0,0.2);  
}  
  
.MuiDialogTitle-root {  
  font-size: 18px;  
  font-weight: bold;  
  margin-bottom: 10px;  
}  
  
.MuiDialogContent-root {  
  margin-bottom: 20px;  
}  
  
.MuiDialogActions-root {  
  margin-top: 20px;  
}  
  
.MuiButton-root {  
  background-color: #4CAF50;  
  color: #fff;  
  padding: 10px 20px;  
  border: none;  
  border-radius: 5px;  
  cursor: pointer;  
}  
  
.MuiButton-root:hover {  
  background-color: #3e8e41;  
}

.active_link{
  background-color: #e1dbff;

}