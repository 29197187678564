.New_Questions{
    width: 100%;
    display: flex;
    text-transform: capitalize;
}
a{
    text-decoration: none;
    margin: 0;
    padding: 0;
    
}

.New_Questions .site{
    flex:1 ;
}
.New_Questions .contener{
    flex: 5;
}
.New_Questions .top, .bottom{
    padding: 10px;
    margin: 20px;
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
}
.New_Questions .top h1{
    color: #ccc;
    font-weight: 400;
}
.New_Questions .bottom {
    display: flex;
    align-items: center;

}
.New_Questions .bottom .left{
    flex: 1;
    text-align: center;
}
.New_Questions .bottom .right{
    flex: 2;
}

.New_Questions .bottom .left img{
    width: 150px;
    /* border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%; */
    text-align: center;
}
.New_Questions .form{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-around;
}
.New_Questions .form .input_form{
    width: 40%;
}
.New_Questions .form .input_form textarea{
    width: 100%;
    padding: 5px;
    padding-top: 0pxx;
    border: none;
    border-bottom: solid 1px #ccc;
    outline: none;
    border: solid 3px #ccc;
    border-radius: 5px;
    min-height: 150px;

}
.New_Questions .form .input_form i{
    font-size: 25px;
    margin-top: 20px;
}
.New_Questions form button{
    width: 150px;
    padding: 7px;
    background-color: teal;
    color: white;
    border: none;
    font-size: 25px;
    text-transform: capitalize;

    outline: none;
}
.New_Questions .btn{
    margin-top: 1rem;
    width: 100%;
    border: none;

    text-align: center;
}
.New_Questions  .error{
    color: red;
}
.New_Questions .left img{
    width: 200px !important;
    height: 200px !important;
    /* border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%; */
}
.password{
    position: relative;
}
.password i{
        
    position: absolute;
    top: 15px;
    right: 5px;
}

/* /////////////////////////////////////////// */
                /* DARK_MOOD */
/* /////////////////////////////////////////// */
.dark, .dark input{
    background-color: #111;
    color:white;
}

.dark label{
    color: #ccc;
}

/* ///////////////////////////////////////// */
@media (max-width:880px) {
    .New_Questions .bottom{
        flex-direction: column;
    }
    .New_Questions .left img{
        margin-bottom: 2rem;
        width: 100px !important; 
        height: 100px !important;
    }
    
}
@media (max-width:560px) {
    .New_Questions .form{
        flex-direction: column;
        align-items: center;
    }
    .New_Questions .form .input_form{
        max-width: 250px;
        width: 100%;
    }

}

/* ///////////////////////////////////////// */
