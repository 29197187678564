.Profile{
    h1{
        font-size: 25px;
        font-weight: 600;
    }
    .main-form{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    h1{
        text-align: center;
    }
    .main{
        width: 500px;
        margin: 2rem auto;
        border-radius: 10px;
        padding: 1rem;
        box-shadow: 2px 4px 10px 1px hsl(0deg 0% 79%);
        text-transform: capitalize;
        display: flex;
        flex-direction: column;
        .form{
            padding-bottom: 50px;
            // height: 300px;
            position: relative;
            h3{
                text-align: center;
            }
            input{
                display: block;
                border: none;
                border-bottom: 1px solid #ccc;
                outline: none;
                padding: 5px;
                width: 100% ;
            }
            .password{
                margin-top: 2rem;
                position: relative;
                svg{
                    position: absolute;
                    width: 20px;
                    right: 0;
                    bottom: 7px;
                    cursor: pointer;
                }
            }
            button{
                transform: translate(50%,50%);
                position: absolute;
                bottom: 10px;
                right: 50%;
                background-color: black;
                border: none;
                color: #fff;
                font-size: 20px;
                padding: 4px ;
                text-transform: capitalize;
                width: 110px;
                border-radius: 3px;
                border :1px solid black;
                transition-duration: 0.3s;
                cursor: pointer;
                &:hover{
                    color: black;
                    background-color: white;
                }
            }
        }
        a{
            
            font-size: 17px;
            text-decoration: underline;
        }
    }
    .top{
        text-align: center;

        margin-bottom: 2rem;
    }
    .error{
        position: absolute;
        color: red;
        transform: translateY(-50%);
        font-size: 13px;
    }
    .error_email{
        position: absolute;
        color: red;
        font-size: 12px;
    }
    .link_create_account{
        display: block;
        transform: translateY(-200%);
        a{
            color: black;
        }
    }
    .table{
        height: 150px;
        td{
            button{
                background-color: rgba(220, 20, 60, 0.06);
    border: 1px dotted darkblue;
    color: crimson;
    margin-right: 20px;
    border-radius: 4px;
            }
        }
    };

}
@media (max-width:563px) {
    .login_page{
        .form{
            button{
                border-radius: 2px !important;
                width: 80px !important;
            }
        }
    }
}