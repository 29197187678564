/* Add this to your Project.css file */  
  
.MuiDialog-paper {  
    background-color: #fff;  
    color: #333;  
    padding: 20px;  
    border-radius: 10px;  
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);  
  }  
    
  .MuiDialogTitle-root {  
    font-size: 18px;  
    font-weight: bold;  
    margin-bottom: 10px;  
  }  
    
  .MuiDialogContent-root {  
    margin-bottom: 20px;  
  }  
    
  .MuiDialogActions-root {  
    margin-top: 20px;  
  }  
    
  .MuiButton-root {  
    background-color: #4CAF50;  
    color: #fff;  
    padding: 10px 20px;  
    border: none;  
    border-radius: 5px;  
    cursor: pointer;  
  }  
    
  .MuiButton-root:hover {  
    background-color: #3e8e41;  
  }
  